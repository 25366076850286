import React, {RefObject} from 'react';
import s from './ActionButton.scss';
import classnames from 'classnames';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {getProductWidgetSettingsFromProps} from '../../commons/styleParamsService';
import {WidgetActionEnum} from '../../commons/enums';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/translations';
import {StatesButton, StatesButtonStates} from 'wix-ui-tpa';
import {classes as addToCartButtonStylable} from './addToCartButton.st.css';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import ProductWidgetLayoutStyle from './../ProductWidgetLayout/ProductWidgetLayout.scss';
import {IProductWidgetSettings} from '../../types/app-types';

export enum DataHook {
  Root = 'action-button',
}

export interface IActionButtonProps extends IProvidedGlobalProps, IProvidedTranslationProps {
  isActionButtonFocused?: boolean;
  onSubmit?: Function;
  showOnlyOnHover?: boolean;
  topGutter?: boolean;
  isCentered?: boolean;
}

class ActionButtonComp extends React.Component<IActionButtonProps> {
  public AddToCartButtonRef: RefObject<StatesButton> = React.createRef();
  private readonly settings: IProductWidgetSettings = getProductWidgetSettingsFromProps(this.props);

  public static defaultProps: Partial<IActionButtonProps> = {
    topGutter: false,
    isCentered: false,
    showOnlyOnHover: false,
  };

  private a11yAnnouncer: Announcer;

  public componentDidMount(): void {
    this.a11yAnnouncer = new Announcer('product-widget-announcer');
  }

  public componentDidUpdate(prevProps: IActionButtonProps): void {
    if (this.props.globals.shouldFocusAddToCartButton !== prevProps.globals.shouldFocusAddToCartButton) {
      this.focusActionButton();
    }
  }

  public componentWillUnmount(): void {
    this.a11yAnnouncer.cleanup();
  }

  private readonly focusActionButton = () => {
    this.AddToCartButtonRef.current.focus();
  };

  private readonly handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    const {onSubmit} = this.props;
    e && e.preventDefault();
    e && e.stopPropagation();
    onSubmit(e);
    if (this.settings.widgetAction === WidgetActionEnum.ADDTOCART) {
      this.a11yAnnouncer.announce(
        this.props.t('sr.ANNOUNCE_ADDED_TO_CART_SINGULAR', {productName: this.props.globals.product.name})
      );
    }
  };

  public render(): React.ReactNode {
    const {t, topGutter, isCentered, showOnlyOnHover} = this.props;

    const text = t(this.getTranslationKeyByAction(this.settings.widgetAction));
    const buttonClasses = classnames(addToCartButtonStylable.root, {
      [s.topGutter]: topGutter,
      [s.centered]: isCentered,
      [s.actionButtonShowOnlyOnHover]: showOnlyOnHover,
      [ProductWidgetLayoutStyle.actionButtonShowOnlyOnHover]: showOnlyOnHover,
    });

    return (
      <StatesButton
        state={StatesButtonStates.IDLE}
        idleContent={text}
        data-hook={DataHook.Root}
        disabled={this.isDisabled}
        ref={this.AddToCartButtonRef}
        onClick={this.handleSubmit}
        type="submit"
        className={classnames(buttonClasses)}
      />
    );
  }

  private get isDisabled(): boolean {
    return this.props.globals.isDisabled && this.settings.widgetAction === WidgetActionEnum.ADDTOCART;
  }

  private getTranslationKeyByAction(widgetAction: string): string {
    if (widgetAction === WidgetActionEnum.NAVIGATE) {
      return 'NAVIGATE_TO_PRODUCT_PAGE_BUTTON';
    } else if (this.isDisabled) {
      return 'ADD_TO_CART_BUTTON_ZERO_PRICE';
    }
    return 'ADD_TO_CART_BUTTON';
  }
}

export const ActionButton = withGlobalProps(withTranslations('globals.texts')(ActionButtonComp));
